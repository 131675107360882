// @ts-nocheck
import { PluginApi, PAAdminLoginResponse } from "@cverse/core-lib-beta";



export class PluginService {

  pluginApi = new PluginApi();

  public async login(account, password) {
    let res: PAAdminLoginResponse = await this.pluginApi.adminLogin({ account, password })
    return res
  }

}
