export default {
  home: 'Home',
  switchLanguage: 'Languages',
  dev_home: {
    makeWeb3SaferEasier: "Make web3-building safer and easier!",
    SDKs: "SDKs",
    Plugins: "Plugins",
    Algorithms: "Algorithms",
    EconomicModel: "Economic Model",
    makeWeb3SaferEasierDesc: "Anyone with ideas can participate in Web3 at the lowest cost",
    startBuilding: "Start building",
    readDoc: "Read Docs",
    allDAppsBuilding: "All DApps Building With Chaosverse OS",
    sdkTitle1: "Multi-language support",
    sdkTitle2: "Easy to develop",
    sdkTitle3: "Provide 50+ smart contracts",
    sdkDesc1: "Support Js,Java,Python,Go etc.",
    sdkDesc2: "Adopt a unified interface specification to deploy, which is convenient for development",
    sdkDesc3: "Support 50+ smart contracts to deploy and call through SDK,covering most business scenarios",
    plugin: "Plugins",
    pluginTitle1: "Safe",
    pluginTitle2: "Easy",
    pluginTitle3: "Cheap",
    pluginDesc1: "All smart contracts have been audited",
    pluginDesc2: "One-click configuration, one-click deployment, support for external links, simple and convenient",//
    pluginDesc3: "Low development and deployment costs, one-click configuration, deployment, and use",
    economicModel: "Economic Model",
    economicModelTitle1: "One-click deploy",
    economicModelTitle2: "One-click debug",
    economicModelTitle3: "One-click deploy",
    economicModelDesc1: "One-click deployment of economic models through tokens,algorithms,and interrelationships",
    economicModelDesc2: "Support debugging, easy to query the debugging results, and correct the model according to the results",
    economicModelDesc3: "After the configuration is complete, one-click publishing on chain, zero-code, easy and fast",
    project: "Project",
    projectTitle1: "One-click generation",
    projectTitle2: "Project templates",
    projectTitle3: "Independent deployment",
    projectDesc1: "One-click generation of projects through plugins, configurations, etc., which can be published directly",
    projectDesc2: "A large number of project template references, from economic model to page design, can be configured and generated with one click",
    projectDesc3: "Support projects Independent domain name resolution deployment, your project is your own decision",
    start: "Start",
    SDKLang: "Multi-language SDK to use Web3 at a lower cost",
    SDKLangAdvantage1: "Reduce development costs by 80%",
    SDKLangAdvantage2: "Minimal smart contract development",
    SDKLangAdvantage3: "Support one-click configuration of 50+ general contracts",
    useImmediately: "Use Immediately",
    morePluginUseImmediately: "Various plugins, no programming required, easy to use",
    morePlugins: "More",
    generationDapp: "One-click generation of DApp",
    generationDappDesc: "Plugin splicing + page configuration, one-click generation of DApp",
    Efficient: "Efficient",
    EfficientDesc: "Configuration generation, one-click deployment, efficient and fast",
    Safety: "Safe",
    SafetyDesc: "Plugin security, contract security, deployment security, all-round protection of project security",
    Cooperation: "Collaboration",
    CooperationDesc: "Decentralized collaboration, project parts are completed separately, seamless docking",
    realTime: "Real-time statistics, data, risks, trends at a glance",
    earlyWarning: "Warning",
    abnormalItems: "Abnormal projects early detection",
    contractRisk: "Contracts risk warning",
    analyze: "Analysis",
    onChain: "On-chain data analysis and statistics",
    realTime1: "Real-time statistical analysis of user data to facilitate timely adjustment of direction",
    increaseTrust: "Credit increase",
    onChain1: "On-chain transaction real feedback to users",
    dataTransparency: "Honest project parties with transparent data are more likely to gain user trust",
    systemArchitectureDiagram:"Chaosverse system architecture diagram",
    systemArchitectureDiagramDesc:"Chaosverse OS is the bridge between the blockchain and the applition layer.",
    developmentMode:"From zero foundation to geeks can experience Web3",
    tableDesc1:"Development type",
    tableDesc2:"Features",
    tableDesc3:"Amount of code",
    tableDesc4:"Suitable for",
    tableDesc5:"Applicable scene",
    tableDesc6:"Chaosverse OS",
    tableDesc7:"The underlying Web3 kernel technology, based on the solidity contract with its own language interface programming",
    tableDesc8:"0 contract code, a very large amount of front-end code",
    tableDesc9:"tech geek",
    tableDesc10:"Technical projects (on-chain monitoring, data analysis, etc.)",
    tableDesc11:"SDKs",
    tableDesc12:"Provide SDK interface code, based on the interface for front-end programming",
    tableDesc13:"0 contract code, a lot of front-end code",
    tableDesc14:"Project parties with certain technical capabilities (willing to use various new functions and special functions)",
    tableDesc15:"Complete project",
    tableDesc16:"Token Handler&IDE",
    tableDesc17:"Write the project economic map through the IDE, and develop the front-end industry through the SDK",
    tableDesc18:"Zero contract code,a lot of front-end code",
    tableDesc19:"Project parties with certain technical capabilities (willing to self-develop the economic system)",
    tableDesc20:"Complete project",
    tableDesc21:"function plugin",
    tableDesc22:"Only need to use Web2 technology and develop through plugin external links",
    tableDesc23:"0 contract code, a small amount of front-end code",
    tableDesc24:"Small team for simple application projects (copy existing projects, some developers of UI and front-end may be missing)",
    tableDesc25:"Existing project or simple project",
    tableDesc26:"Project",
    tableDesc27:"0 code development and deployment, only simple economic mode configuration is required",
    tableDesc28:"zero-code",
    tableDesc29:"Minimalist projects, personal projects, operators (quick configuration, quick start)",
    tableDesc30:"Operational Activities, Independent Projects",
    Version:"Version",
    Deploy:"Deploy",
    commingSoon:"Coming soon",
    Drafts:"Drafts",
    Docs:"Docs",
    pluginDetail:"Plugin detail",
    pluginDescription:"Plugin description",
    Author:"Author",
    UpdateTime:"Update time",
    Explorer:"Explorer",
    Model:"Model",
    Sources:"Sources",
    Admin:"Admin",
    Embed:"Embed",
    Txs:"Txs",
    ContractCode:"Smart contract code",
    FrontEndCode:"Front-end code",
    FrontEndCodeDesc:"Since the code is not easy to display, you need to download and view it after purchasing",
    DeployNewContract:"Deploy Plugin",
    ContractName:"Instance name",
    ContractDesc:"Instance description",
    placeholder: "Input",
    DeployedPlugins: "Deployed Plugins",
    name: "Name",
    describe: "Description",
    PluginsUsed: "Plugins used",
    Unpublished: "Unpublished",
    CreateTime: "Release time",
    lastUpdateTime: "Last update",
    Operations: "Operations",
    manage: "Manage",
    Delete: "Delete",
    Edit: "Continue",
    transactionHash: "Transaction hash",
    UsedContract: "Contract",
    From: "From",
    To: "To",
    Events: "Events",
    Block: "Block",
    nextStep:"Next step",
    contractDeployed:"contract deployed",
    backStep:"Previous",
    notDeployToken:"Contract not published",
    pleaceEnterName:"Please enter a name",
    pleaceEnterDescription:"Please enter a description",
    isTrueBack:"Confirm to return to the previous step",

    notConnect: 'wallet not connected',
    all:"All",
    transactionData:"Transaction data",
    eventsData:"Event data",
    walletErrIsToHome:"Link connection error, confirm to return to the home page.",
    addressErrIsToHome:"The accounts do not match, confirm and return to the home page",
    deploy: {
      tips: "Tops",
      contractHasBeenDeployed: "The contract has been successfully deployed, please proceed to the next step",
    }
  },
  dev_ide: {
    projectList: 'Project List',
    createProject: 'Create Project',
    addProject: "Add Project",
    createNewBlank: "Create a new blank",
    confirm: "Confirm",
    cancel: "Cancel",
    projectSettings: 'Project Setting',
    pleaseInputName: 'Please enter a project name',
    pleaseInputDes: 'Please enter a project description',
    addSuccessed: 'Successfully added item',
    addFailed: 'Failed to add item',
    pleaseInputIcon: 'Please upload a picture',
    editProjectInfo: 'Edit project information',
    delete: 'Delete',
    customTemplate: 'Custom Template',
    view: 'Visit',
    edit: 'Edit',
    no_deploy_forward: 'You have not deployed a domain name, go to',
    no_deploy_back: ' the panel can be accessed after the domain name is deployed',
    no_project_forward: 'Did not find your project, click',
    no_project_back: 'create it',
    please: 'Please',
    connectWallect: 'connect wallet',
    viewProject: 'view your project',
    confirmDeleteTip: 'Are you sure you want to delete this'
  }
}
