<template>
  <div class="container">
    <router-view />
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #020112;
}
</style>
