export default {
  home: '主页',
  switchLanguage: '切换语言',
  placeholder9: '',
  dev_home: {
    makeWeb3SaferEasier: "Make web3-building safer and easier!",
    SDKs: "SDKs",
    Plugins: "Plugins",
    Algorithms: "Algorithms",
    EconomicModel: "Economic Model",
    makeWeb3SaferEasierDesc: "让每一个有想法的人最低成本参与Web3创造",
    startBuilding: "开始建设",
    readDoc: "阅读文档",
    allDAppsBuilding: "All DApps Building With Chaosverse OS",
    sdkTitle1: "多语言支持",
    sdkTitle2: "便于开发",
    sdkTitle3: "支持50+智能合约",
    sdkDesc1: "支持Js、Java、Python、Go等多语言，更泛用",
    sdkDesc2: "采用统一的接口规范部署提供，开发方便",
    sdkDesc3: "支持50+智能合约通过SDK部署调用，大部分项目均可直接搞定",
    plugin: "插件",
    pluginTitle1: "安全的",
    pluginTitle2: "方便的",
    pluginTitle3: "便宜的",
    pluginDesc1: "所有合约经过安全审计，确保插件安全",
    pluginDesc2: "一键配置，一键部署，支持外链，简单方便",
    pluginDesc3: "开发部署成本低，一键实现配置、部署、使用",
    economicModel: "经济模型",
    economicModelTitle1: "一键部署",
    economicModelTitle2: "一键调试",
    economicModelTitle3: "一键发布",
    economicModelDesc1: "通过资产、算法、相互关系一键完成经济模型部署",
    economicModelDesc2: "支持调试，方便查询调试结果，根据结果修正模型",
    economicModelDesc3: "配置完成后，一键发布上链，即可使用，0代码方便快捷",
    project: "项目",
    projectTitle1: "一键生成",
    projectTitle2: "项目模板",
    projectTitle3: "独立部署",
    projectDesc1: "通过插件、配置等一键生成项目，可直接发布",
    projectDesc2: "超多项目模板参考，从经济模型也页面设计均可一键配置生成",
    projectDesc3: "支持项目独立域名解析部署，您的项目您自己做主",
    start: "Start",
    SDKLang: "多语言SDK更低成本使用Web3",
    SDKLangAdvantage1: "降低80%开发成本",
    SDKLangAdvantage2: "最小化智能合约开发",
    SDKLangAdvantage3: "支持50+通用合约一键配置",
    useImmediately: "立即使用",
    morePluginUseImmediately: "多种插件，无需编程，立即使用",
    morePlugins: "探索更多",
    generationDapp: "DApp一键生成",
    generationDappDesc: "插件拼接+页面配置，一键生成DApp",
    Efficient: "高效",
    EfficientDesc: "配置生成，一键部署，高效快捷",
    Safety: "安全",
    SafetyDesc: "插件安全、合约安全、部署安全，全方位保障项目安全性",
    Cooperation: "协作",
    CooperationDesc: "去中心化协作，项目部分各自完成，无缝对接",
    realTime: "实时统计，数据、风险、趋势一目了然",
    earlyWarning: "预警",
    abnormalItems: "项目异常，最早发现，避免出现",
    contractRisk: "合约风险预警",
    analyze: "分析",
    onChain: "链上数据分析统计",
    realTime1: "用户数据实时统计分析，方便及时调整方向",
    increaseTrust: "增信",
    onChain1: "链上交易真实反馈给用户",
    dataTransparency: "数据透明，诚实的项目方更容易取得用户信任",
    systemArchitectureDiagram:"Chaosverse system architecture diagram",
    systemArchitectureDiagramDesc:"Chaosverse OS is the bridge between the blockchain and the applition layer.",
    developmentMode:"0基础到极客，均可感受Web3",
    tableDesc1:"开发类型",
    tableDesc2:"特点",
    tableDesc3:"代码量",
    tableDesc4:"适合对象",
    tableDesc5:"适合场景",
    tableDesc6:"Chaosverse OS",
    tableDesc7:"底层Web3内核技术，基于solidity合约自带语言接口编程",
    tableDesc8:"0合约代码极大量前端代码",
    tableDesc9:"技术极客",
    tableDesc10:"技术类项目（链上监控、数据分析等）",
    tableDesc11:"SDKs",
    tableDesc12:"提供SDK接口代码，基于接口进行前端编程",
    tableDesc13:"0合约代码大量前端代码",
    tableDesc14:"有一定技术能力的项目方（愿意使用各类新功能、特殊功能）",
    tableDesc15:"完整项目",
    tableDesc16:"Token处理算法&IDE",
    tableDesc17:"通过IDE编写项目经济图，通过SDK开发前端业面",
    tableDesc18:"0合约代码大量前端代码",
    tableDesc19:"有一定技术能力的项目方（愿意自研经济系统）",
    tableDesc20:"完整项目",
    tableDesc21:"功能插件",
    tableDesc22:"仅需使用Web2技术，通过插件外链形式开发",
    tableDesc23:"0合约代码少量前端代码",
    tableDesc24:"简易应用项目小团队（复制已有项目，UI、前端可能部分开发者缺失）",
    tableDesc25:"已有项目简易项目",
    tableDesc26:"项目",
    tableDesc27:"0代码开发部署，仅需简单经济模式配置",
    tableDesc28:"0代码",
    tableDesc29:"极简项目、个人项目、运营方（快速配置、快速启动）",
    tableDesc30:"运营活动，独立项目",
    Version:"版本",
    Deploy:"发布",
    commingSoon:"即将上线",
    Drafts:"草稿",
    Docs:"文档",
    pluginDetail:"插件详情",
    pluginDescription:"插件说明",
    Author:"作者",
    UpdateTime:"更新时间",
    Explorer:"Explorer",
    Model:"Model",
    Sources:"Sources",
    Admin:"Admin",
    Embed:"Embed",
    Txs:"Txs",
    ContractCode:"合约代码",
    FrontEndCode:"前端代码",
    FrontEndCodeDesc:"由于代码不好展示，需购买后下载查看",
    DeployNewContract:"发布新插件",
    basicInfo: '基本信息',  // 未翻译
    deployContract: '发布合约',  // 未翻译
    creation: '创建',  // 未翻译
    ContractName:"实例名称",
    ContractDesc:"实例描述",
    placeholder: "输入",
    DeployedPlugins: "Deployed Plugins",
    name: "名称",
    describe: "描述",
    PluginsUsed: "所用插件",
    Unpublished: "未发布",
    CreateTime: "发布时间",
    lastUpdateTime: "末次更新",
    Operations: "操作",
    manage: "管理",
    Delete: "删除",
    Edit: "继续",
    transactionHash: "交易hash",
    UsedContract: "所属合约",
    From: "From",
    To: "To",
    Events: "Events",
    Block: "所属区块",
    nextStep:"下一步",
    contractDeployed:"合约创建完成",
    backStep:"上一步",
    notDeployToken:"没有发布合约",
    pleaceEnterName:"请输入名称",
    pleaceEnterDescription:"请输入描述",
    isTrueBack:"确认返回上一步",
    notConnect:"没有链接钱包",
    all:"全部",
    transactionData:"交易数据",
    eventsData:"事件数据",
    walletErrIsToHome:"链连接错误，确认返回首页。",
    addressErrIsToHome:"账号不匹配，确认返回首页",
    deploy: {
      tips: "提示",
      contractHasBeenDeployed: "合约已部署成功，请进行下一步",
    }
  },
  dev_ide: {
    projectList: '项目列表',
    createProject: '创建项目',
    addProject: "添加项目",
    createNewBlank: "新建空白文档",
    confirm: "确认",
    cancel: "取消",
    projectSettings: '项目设置',
    pleaseInputName: '输入项目名称',
    pleaseInputDes: '输入项目描述',
    addSuccessed: '添加项目成功',
    addFailed: '添加项目失败',
    pleaseInputIcon: '请上传图片',
    editProjectInfo: '编辑项目信息',
    delete: '删除',
    customTemplate: '自定义模板',
    view: '访问',
    edit: '编辑',
    no_deploy_forward: '您还未部署域名，去',
    no_deploy_back: '面板部署域名后即可访问',
    no_project_forward: '没发现您的项目,点击',
    no_project_back: '创建吧',
    please: '请',
    connectWallect: '连接钱包',
    viewProject: '查看您的项目',
    confirmDeleteTip: '确定删除此'
  }
}
