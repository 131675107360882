<template>
  <router-view />
</template>

<script lang="ts" setup></script>

<style lang="scss">
// @import "@cverse/core-lib-vue-beta/dist/CVerseCoreLibVue.css";
// @import "./assets/styles/theme.scss";
// @import "./assets/styles/theme.module.scss";
body {
  overflow: hidden;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100vh;
}
</style>
