<template>
  <div class="header">
    <div class="logo">
      <img src="../../assets/images/home/logo.png" alt="" />
    </div>
    <div class="icon_link">
      <img
        src="../../assets/images/home/discord.png"
        @click="jump('https://discord.gg/pBnCsPTd')"
        alt=""
      />
      <a :href="url" target="_blank">
        <img src="../../assets/images/home/icon.png" alt="" />
        Start Building
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
function changeMenu() {
  router.replace({
    path: "console/ide",
  });
}
const jump = (value: string) => {
  window.open(value);
};
const url = window.location.origin + "/console/";
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  .logo {
    width: 131px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon_link {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }

    a {
      display: inline-block;
      width: 159px;
      height: 40px;
      border-radius: 30px 30px 30px 30px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background-color: transparent;
      font-family: Helvetica, Helvetica;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      line-height: 40px;
      margin-left: 24px;
      cursor: pointer;

      img {
        width: 12.61px;
        height: 12.61px;
      }
      &:hover {
        border: 1px solid #fd6b4c;
      }
    }
  }
}
</style>
