<template>
  <div class="five">
    <div class="title">Case Display</div>

    <el-carousel
      :interval="3000"
      type="card"
      height="820px"
      arrow="never"
      :loop="true"
      indicator-position="none"
      :motion-blur="true"
      :pause-on-hover="true"
    >
      <el-carousel-item v-for="slide in carouselItems" :key="slide.id">
        <img :src="slide.image" class="carousel-content" />
        <div class="mining">{{ slide.name }}</div>
      </el-carousel-item>
    </el-carousel>

    <div class="bot-btn">
      <a :href="url" target="_blank">
        <img src="../../assets/images/home/icon.png" alt="" />
        Start Building
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ElCarousel, ElCarouselItem } from "element-plus";

import { useRoute, useRouter } from "vue-router";
import p1 from "../../assets/images/swper/p1.png";
import p2 from "../../assets/images/swper/p2.png";
import p3 from "../../assets/images/swper/p3.png";
import p4 from "../../assets/images/swper/p4.png";
import p5 from "../../assets/images/swper/p5.png";
import p6 from "../../assets/images/swper/p6.png";
import p7 from "../../assets/images/swper/p7.png";

const carouselItems = [
  { id: 1, image: p1, name: "Upgrade" },
  { id: 2, image: p2, name: "Blind box" },
  { id: 3, image: p3, name: "Mining" },
  { id: 4, image: p4, name: "Airdrop" },
  { id: 5, image: p5, name: "Lottery" },
  { id: 6, image: p6, name: "Guess the size" },
  { id: 7, image: p7, name: "Mining" },
];

const route = useRoute();
const router = useRouter();
function changeMenu() {
  router.replace({
    path: "console/ide",
  });
}

const url = window.location.origin + "/console/";
</script>

<style lang="scss" scoped>
.five {
  width: 100%;
  overflow: hidden;

  .title {
    font-family: Arial, Arial;
    font-weight: bold;
    font-size: 40px;
    color: #d1d8ec;
    line-height: 47px;
    text-align: center;
    margin-top: 106px;
  }
  .carousel-content {
    width: 390px;
    height: 721px;
  }
  .mining {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #d1d8ec;
    margin-top: 55px;
    display: none;
  }

  .bot-btn {
    width: 100%;
    background-image: url("../../assets/images/home/bg6.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    a {
      display: inline-block;
      width: 231px;
      height: 80px;
      margin-top: 440px;
      margin-bottom: 50px;
      border-radius: 45px 45px 45px 45px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: transparent;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #fd532f;
      line-height: 80px;
      text-align: center;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      &:hover {
        border: 1px solid #fd6b4c;
      }
    }
  }
}

:deep(.el-carousel__mask) {
  background-color: transparent;
  background-color: #020112;
  :deep(.mining) {
    display: none;
  }
}
.el-carousel {
  margin-top: 78px;
}
.el-carousel__item--card {
  opacity: 0;
}
.el-carousel__item--card.is-in-stage {
  display: block;
  opacity: 0.6;
}
.el-carousel__item--card.is-active {
  opacity: 1;
  display: block;
  .mining {
    display: block;
  }
}
</style>
