<template>
  <div class="login-container">
    <div class="form-box">
      <div class="icon-con">
        <img src="../../assets/images/chaosverse_logo@.png" />
      </div>
      <div class="form-container">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          status-icon
          :rules="rules"
          label-width="120px"
          class="chaos-element-ui-new"
          label-position="top"
        >
          <el-form-item label="Username" prop="account">
            <el-input class="chaos-element-ui-new" v-model="ruleForm.account" style="width: 100%;" />
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input
              class="chaos-element-ui-new"
              v-model="ruleForm.password"
              type="password"
              autocomplete="off"
              style="width: 100%;"
            />
          </el-form-item>
          <div class="footer">
            <div class="footer-login-btn" @click="submitForm(ruleFormRef)">登录</div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { FormInstance } from 'element-plus'
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {PluginService} from "../../service/PluginService"
import { ElMessage } from 'element-plus'

let pluginService
onMounted(async () => {
  pluginService = new PluginService();
})

const ruleFormRef = ref<FormInstance>()

const ruleForm = reactive({
  password: '',
  account: ''
})

const rules = reactive({
  account: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
  password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
})
let router = useRouter()
const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return false
  formEl.validate(async (valid) => {
    if (valid) {
      try {
        let res = await pluginService.login(ruleForm.account, ruleForm.password)
        if (res.code === 200 && res.data) {
          localStorage.setItem('admin', res.data)
          router.push('/home')
        } else {
          ElMessage({
            message: res.msg,
            customClass: 'chaos-element-ui',
          })
        }
        
      } catch (error) {
        ElMessage({
          message: '登录失败，请稍后重试',
          customClass: 'chaos-element-ui',
        })
      }
      
    }
  })
}



</script>
<style lang="scss" scoped>
.login-container {
  background-color: #212226;
  width: 100%;
  height: 100%;
  .form-box {
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    .form-container {
      width: 500px;
      background-color: #2c2e33;
      padding: 25px;
      border-radius: 12px;
      margin: 0 auto;
      margin-top: 25px;
    }
  }

  .footer-login-btn {
    width: 80px;
    height: 32px;
    background: #e56a45;
    border-radius: 6px 6px 6px 6px;
    margin: 0 auto;
    line-height: 32px;
    color: #FFFFFF;
    cursor: pointer;
  }
}
</style>
