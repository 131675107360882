<template>
  <div class="two">
    <div class="text">
      <div class="title">Rule System Architecture Diagram</div>
      <div class="desc">
        Rule OS is the bridge between the blockchain and the applition layer.
      </div>
    </div>
    <div class="image">
      <img src="../../assets/images/home/bg2.png" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.two {
  width: 100%;
  position: relative;
  overflow: hidden;
  .text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .title {
    font-family: Arial, Arial;
    font-weight: bold;
    font-size: 40px;
    color: #d1d8ec;
    line-height: 47px;
    text-align: center;
    margin-top: 103px;
  }
  .desc {
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 20px;
    color: #d1d8ec;
    line-height: 23px;
    text-align: center;
    margin-top: 16px;
  }
  .image {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
</style>
