<template>
  <div class="three">
    <div class="title">Web3 Ecological Aggregation</div>
    <div class="desc">
      Drag-and-drop process, page configurator, and convenient DApp construction
      allow you to quickly<br />
      develop DApps even if you don’t have a Web3 foundation.
    </div>
    <div class="image">
      <img src="../../assets//images/home/bg3.png" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.three {
  width: 100%;
  overflow: hidden;

  .title {
    font-family: Arial, Arial;
    font-weight: bold;
    font-size: 40px;
    color: #d1d8ec;
    line-height: 47px;
    text-align: center;
  }
  .desc {
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 20px;
    color: #d1d8ec;
    line-height: 23px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 103px;
  }
  .image {
    max-width: 933px;
    max-height: 933px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
