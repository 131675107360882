import { createI18n } from 'vue-i18n'

import en from "./locale/en";
import zh from "./locale/zh";
import ja from "./locale/ja";
import ko from "./locale/ko";


const messages = {
    en,
    zh,
    ja,
    ko,
}

const i18n = createI18n({
    legacy:false,
    globalInjection: true,
    warnHtmlMessage: false,
    locale: localStorage.getItem('lang') || 'en',
    messages,
})


export default i18n;
