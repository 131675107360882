import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/layout/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "plugin" */ "../views/home/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/login/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const whiteList = ["/login"];
// router.beforeEach((to, from, next) => {
//   if (whiteList.includes(to.path)) {
//     next();
//   } else {
//     if (localStorage.getItem("admin")) {
//       next();
//     } else {
//       next("/login");
//     }
//   }
// });
router.afterEach(() => {
  window.scrollTo(0, 0); // 切换路由之后滚动条始终在最顶部
});
export default router;
