<template>
  <div class="four">
    <div class="title">
      Visual Configuration Intelligently <br />Completes DApp Development
    </div>
    <div class="desc">
      Effortlessly customize and create decentralized applications with seamless
      layout, design, and <br />
      functionality configuration, accelerating innovation in blockchain
      applications.
    </div>
    <div class="image">
      <img src="../../assets//images/home/bg4.png" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.four {
  width: 100%;
  margin-top: 240px;
  overflow: hidden;

  .title {
    font-family: Arial, Arial;
    font-weight: bold;
    font-size: 50px;
    color: #d1d8ec;
    line-height: 59px;
    text-align: center;
  }
  .desc {
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 20px;
    color: #d1d8ec;
    line-height: 23px;
    text-align: center;
    margin-top: 50px;
  }
  .image {
    max-width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
