<template>
  <div class="one">
    <div class="title">
      Build Web3 Projects<span> Quickly</span> and <span>Efficiently</span>
    </div>
    <div class="desc">
      Flexibly defined web3 system, efficient development and integrated
      marketing help Web3 projects take off from 0-1!
    </div>
    <div class="btn-box">
      <a :href="url" target="_blank">
        <img src="../../assets/images/home/icon1.png" alt="" />
        Start Building
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
function changeMenu() {
  router.replace({
    path: "console/ide",
  });
}
const url = window.location.origin + "/console/";
</script>

<style lang="scss" scoped>
.one {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/home/bg1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  .title {
    font-family: Arial, Arial;
    font-weight: bold;
    font-size: 64px;
    color: #d1d8ec;
    text-align: center;
    margin-top: 255px;
    span {
      color: #fd532f;
    }
  }
  .desc {
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 20px;
    color: #d1d8ec;
    line-height: 23px;
    text-align: center;
    margin-top: 36px;
  }
  .btn-box {
    a {
      display: inline-block;
      width: 231px;
      height: 80px;
      background: #fd532f;
      border-radius: 45px 45px 45px 45px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      line-height: 80px;
      margin-top: 120px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background: #fd6b4c;
      }
    }
  }
}
</style>
